var render = function () {
  var _vm$configuration2, _vm$$v, _vm$$v$configuration, _vm$$v2, _vm$$v2$configuration, _vm$$v3, _vm$$v3$configuration, _vm$$v4, _vm$$v4$configuration, _vm$configuration3, _vm$configuration4, _vm$participantModal, _vm$configuration5, _vm$configuration5$pa, _vm$participantModal2, _vm$participantModal3;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "autenti-form"
  }, [_c('sygni-loader', {
    attrs: {
      "isLoading": _vm.isLoading
    }
  }), _c('sygni-container-title', {
    staticClass: "mb-5",
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function fn() {
        var _vm$configuration;

        return [_c('div', {
          staticClass: "actions"
        }, [!_vm.isGlobal && (_vm$configuration = _vm.configuration) !== null && _vm$configuration !== void 0 && _vm$configuration.active && !_vm.isDisabled ? _c('sygni-rect-button', {
          staticClass: "ml-4 mr-4 load-config filled black",
          attrs: {
            "disabled": false
          },
          on: {
            "click": _vm.showGlobalConfigModal
          }
        }, [_vm._v("Use global configuration")]) : _vm._e(), _c('sygni-toggle-switch', {
          attrs: {
            "disabled": _vm.isDisabled,
            "label": "ACTIVATE"
          },
          model: {
            value: _vm.configuration.active,
            callback: function callback($$v) {
              _vm.$set(_vm.configuration, "active", $$v);
            },
            expression: "configuration.active"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('span', [_vm._v("Autenti " + _vm._s(_vm.isGlobal ? 'global' : 'document') + " configuration")])]), (_vm$configuration2 = _vm.configuration) !== null && _vm$configuration2 !== void 0 && _vm$configuration2.active ? [_c('div', {
    staticClass: "options"
  }, [_c('div', {
    staticClass: "options__container"
  }, [_c('div', {
    staticClass: "options__single"
  }, [_c('sygni-input', {
    staticClass: "secondary",
    attrs: {
      "label": "DOCUMENT NAME",
      "validation": (_vm$$v = _vm.$v) === null || _vm$$v === void 0 ? void 0 : (_vm$$v$configuration = _vm$$v.configuration) === null || _vm$$v$configuration === void 0 ? void 0 : _vm$$v$configuration.documentName,
      "disabled": _vm.isDisabled
    },
    on: {
      "input": _vm.commitFormUpdate
    },
    model: {
      value: _vm.configuration.documentName,
      callback: function callback($$v) {
        _vm.$set(_vm.configuration, "documentName", $$v);
      },
      expression: "configuration.documentName"
    }
  })], 1), _c('div', {
    staticClass: "options__single"
  }, [_c('sygni-multi-select', {
    attrs: {
      "label": "TAGS",
      "clear-on-select": false,
      "close-on-select": false,
      "multiple": true,
      "options": _vm.tags,
      "displayValues": true,
      "preserve-search": false,
      "searchable": true,
      "select-all": true,
      "validation": (_vm$$v2 = _vm.$v) === null || _vm$$v2 === void 0 ? void 0 : (_vm$$v2$configuration = _vm$$v2.configuration) === null || _vm$$v2$configuration === void 0 ? void 0 : _vm$$v2$configuration.tags,
      "disabled": _vm.isDisabled
    },
    on: {
      "input": _vm.updateTags,
      "toggleAll": _vm.toggleAllTags
    },
    model: {
      value: _vm.selectedTags,
      callback: function callback($$v) {
        _vm.selectedTags = $$v;
      },
      expression: "selectedTags"
    }
  })], 1), _c('div', {
    staticClass: "options__single"
  }, [_c('sygni-select', {
    staticClass: "secondary",
    attrs: {
      "label": 'SIGNATURE MODEL',
      "searchable": true,
      "options": _vm.signatureModels,
      "validation": (_vm$$v3 = _vm.$v) === null || _vm$$v3 === void 0 ? void 0 : (_vm$$v3$configuration = _vm$$v3.configuration) === null || _vm$$v3$configuration === void 0 ? void 0 : _vm$$v3$configuration.signatureModel,
      "disabled": _vm.isDisabled
    },
    on: {
      "input": _vm.commitFormUpdate
    },
    model: {
      value: _vm.configuration.signatureModel,
      callback: function callback($$v) {
        _vm.$set(_vm.configuration, "signatureModel", $$v);
      },
      expression: "configuration.signatureModel"
    }
  })], 1), _c('div', {
    staticClass: "options__single"
  }, [_c('sygni-select', {
    staticClass: "secondary",
    attrs: {
      "label": 'LANGUAGE',
      "searchable": true,
      "options": _vm.languages,
      "validation": (_vm$$v4 = _vm.$v) === null || _vm$$v4 === void 0 ? void 0 : (_vm$$v4$configuration = _vm$$v4.configuration) === null || _vm$$v4$configuration === void 0 ? void 0 : _vm$$v4$configuration.language,
      "disabled": _vm.isDisabled
    },
    on: {
      "input": _vm.commitFormUpdate
    },
    model: {
      value: _vm.configuration.language,
      callback: function callback($$v) {
        _vm.$set(_vm.configuration, "language", $$v);
      },
      expression: "configuration.language"
    }
  })], 1)])]), _c('div', {
    staticClass: "wysiwyg-editor"
  }, [_c('p', {
    staticClass: "label"
  }, [_vm._v("MESSAGE FOR PARTICIPANTS")]), _c('editor-component', {
    attrs: {
      "layout": "clean",
      "content": _vm.configuration.messageToParticipants,
      "supportImages": false,
      "editable": !_vm.isDisabled
    },
    on: {
      "blur": _vm.handleMessageUpdate
    }
  })], 1), _c('div', {
    staticClass: "participants"
  }, [_c('div', {
    staticClass: "title-group mb-4"
  }, [_c('p', {
    staticClass: "title"
  }, [_vm._v("Default participant configuration")]), _c('sygni-toggle-switch', {
    attrs: {
      "disabled": _vm.isDisabled,
      "label": "PARTICIPATION PRIORITY"
    },
    on: {
      "input": _vm.commitFormUpdate
    },
    model: {
      value: _vm.configuration.participationPriority,
      callback: function callback($$v) {
        _vm.$set(_vm.configuration, "participationPriority", $$v);
      },
      expression: "configuration.participationPriority"
    }
  })], 1), !_vm.isDisabled ? _c('sygni-rect-button', {
    staticClass: "participants__btn outline black",
    on: {
      "click": function click($event) {
        return _vm.openParticipantModal('add', null);
      }
    }
  }, [_vm._v("Add participant")]) : _vm._e(), _c('ParticipantsTable', {
    attrs: {
      "isDisabled": _vm.isDisabled,
      "items": ((_vm$configuration3 = _vm.configuration) === null || _vm$configuration3 === void 0 ? void 0 : _vm$configuration3.participants) || [],
      "participationPriority": (_vm$configuration4 = _vm.configuration) === null || _vm$configuration4 === void 0 ? void 0 : _vm$configuration4.participationPriority
    },
    on: {
      "deleteParticipant": function deleteParticipant($event) {
        return _vm.openDeletingParticipantModal($event);
      },
      "editParticipant": function editParticipant($event) {
        return _vm.openParticipantModal('edit', $event);
      },
      "updateItems": _vm.handleParticipantsUpdate
    }
  }), (_vm$participantModal = _vm.participantModal) !== null && _vm$participantModal !== void 0 && _vm$participantModal.show ? _c('ParticipantModal', {
    attrs: {
      "templateId": _vm.templateId,
      "participantsLength": (_vm$configuration5 = _vm.configuration) === null || _vm$configuration5 === void 0 ? void 0 : (_vm$configuration5$pa = _vm$configuration5.participants) === null || _vm$configuration5$pa === void 0 ? void 0 : _vm$configuration5$pa.length,
      "mode": _vm.mode,
      "type": (_vm$participantModal2 = _vm.participantModal) === null || _vm$participantModal2 === void 0 ? void 0 : _vm$participantModal2.mode,
      "participantData": (_vm$participantModal3 = _vm.participantModal) === null || _vm$participantModal3 === void 0 ? void 0 : _vm$participantModal3.participantData,
      "dictionaries": _vm.dictionaries
    },
    on: {
      "cancel": _vm.closeParticipantModal,
      "close": _vm.closeParticipantModal,
      "confirm": _vm.handleParticipantModalAction
    }
  }) : _vm._e()], 1)] : _vm._e(), _vm.showSaveButton ? _c('sygni-rounded-button', {
    staticClass: "filled red button secondary mt-5",
    on: {
      "click": function click($event) {
        return _vm.handleSubmit(true, _vm.templateId);
      }
    }
  }, [_vm._v("Save changes")]) : _vm._e(), _vm.mode === 'global' ? _c('div', {
    attrs: {
      "clas": "router-link"
    },
    on: {
      "click": function click($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_c('div', {
    staticClass: "cancel-button mt-4"
  }, [_vm._v("Cancel")])]) : _vm._e(), _c('sygni-modal', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.deletingParticipantId,
      expression: "deletingParticipantId"
    }],
    attrs: {
      "isLoading": _vm.isLoading,
      "header": "Are you sure?",
      "description": "Do you really want to delete this participant?",
      "cancelText": "No",
      "confirmText": "Yes"
    },
    on: {
      "close": _vm.closeDeleteConfirmationModal,
      "cancel": _vm.closeDeleteConfirmationModal,
      "confirm": _vm.deleteParticipant
    }
  }), _c('sygni-modal', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.loadGlobalConfigModal.show,
      expression: "loadGlobalConfigModal.show"
    }],
    attrs: {
      "header": _vm.loadGlobalConfigModal.header,
      "description": _vm.loadGlobalConfigModal.description,
      "cancelText": _vm.loadGlobalConfigModal.cancelText,
      "confirmText": _vm.loadGlobalConfigModal.confirmText
    },
    on: {
      "cancel": _vm.closeGlobalConfigModal,
      "close": _vm.closeGlobalConfigModal,
      "confirm": _vm.useGlobalConfiguration
    }
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }